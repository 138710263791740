import { Controller } from "@hotwired/stimulus"
import ko from 'knockout'

ko.bindingHandlers.timer = {
  update: function (element, valueAccessor) {
    var method = ko.unwrap(valueAccessor());
    var timer = setInterval(function() {
      method();
    }, 500);
  }
};

class Company {
  constructor() {
    this.users = ko.observableArray();

    this.numberOfUsers = ko.computed(function() {
      return this.users().length;
    }.bind(this));

    this.numberOfPresentUsers = ko.computed(function() {
      var number = 0
      ko.utils.arrayForEach(this.users(), function(user) {
        if (user.isPresent()) {
          number += 1;
        }
      });
      return number;
    }.bind(this));

    this.addEmptyUserIfNecessary = function() {
      if (this.numberOfUsers() == this.numberOfPresentUsers()) {
        this.addEmptyUser();
      }
    }.bind(this)
  }

  addEmptyUser() {
    this.users.push(new User());
  };
}

class User {
  constructor() {
    this.name = ko.observable();
    this.email = ko.observable();
    this.office_id = ko.observable();
    this.phone = ko.observable();

    this.isPresent = ko.computed(function() {
      return (this.name() && this.email());
    }.bind(this));
  }
};

export default class extends Controller {
  connect() {
    var company = new Company();
    ko.applyBindings(company, this.element);
    company.addEmptyUser();
    company.addEmptyUser();
  }
}
