import { Controller } from "@hotwired/stimulus"
import ko from 'knockout'
import { i18n } from "../i18n"

class Order {
  constructor() {
    var self = this;
    self.orderLines = ko.observableArray();

    self.total = ko.computed(function(){
      var total = 0.0;
      ko.utils.arrayForEach(self.orderLines(), function(orderLine) {
        if (orderLine.isPresent()) {
          total += orderLine.subTotal();
        }
      });
      return total;
    });

    // for presentation purposes only
    self.totalPrice = ko.computed(function(){
      var total = self.total();
      var formatted_price = self.formatPrice(total);

      return formatted_price;
    });
  }

  // uses javascript I18n to format the currency as rails I18n
  formatPrice(total) {
    return i18n.numberToCurrency(total);
  };
}

class OrderLine {
  constructor(modelOrderLine, modelProduct, disabled) {
    var self = this;

    self.amount = ko.observable(modelOrderLine.amount);
    self.product = ko.observable(modelProduct);
    // if the orderline does not have a price,
    // take the price from its product or default to 0
    self.price = ko.observable(modelOrderLine.price || modelProduct.price || 0);
    self.note = ko.observable(modelOrderLine.note);
    self.disabled = ko.observable(disabled);

    self.productName = ko.computed(function () {
      return self.product().name;
    });

    self.productDescription = ko.computed(function () {
      return self.product().description || "";
    });

    self.productPrice = ko.computed(function () {
      return i18n.numberToCurrency(self.product().price);
    });

    self.subTotal = ko.computed(function () {
      if (self.amount() && self.price()) {
        return self.amount() * self.price();
      }
    });

    self.isPresent = ko.computed(function () {
      return self.amount() > 0;
    });

    self.orderStatus = ko.computed(function () {
      if (self.disabled()) {
        return "product-available";
      } else {
        return self.isPresent() ? "product-ordered" : "product-available";
      }
    });
  }
}

export default class extends Controller {
  static targets = ["amountInput", "line", "submit", "total"]

  connect() {
    var order = new Order();

    // add orderLine viewmodel
    this.lineTargets.forEach(function(node) {
      // load our Rails models, converted to json
      var orderLineModel = JSON.parse(node.dataset.orderline);
      var productModel = JSON.parse(node.dataset.orderlineproduct);

      // read only or edit form
      var disabledOrderLine = node.dataset.disabled == "true";

      // create orderlines and add them to order viewModel
      var orderLine = new OrderLine(orderLineModel, productModel, disabledOrderLine);
      order.orderLines.push(orderLine);

      ko.applyBindings(orderLine, node);
    });

    ko.applyBindings(order, this.totalTarget)

    this.amountInputTargets.forEach(node => {
      node.addEventListener("change", () => {
        this.submitTarget.disabled = false;
      });
    })
  }
}
