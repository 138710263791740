import { Controller } from "@hotwired/stimulus"

if (window.Rails) {
  const Rails = window.Rails
} else {
  const Rails = require("@rails/ujs")
}

// Auto Save Checkbox
//
// Automatically submits a checkbox via Ajax whenever it changes state.
export default class extends Controller {
  connect() {
    let checkboxes = this.element.querySelectorAll('input[type=checkbox]')
    for (var i = checkboxes.length - 1; i >= 0; i--) {
      const checkbox = checkboxes[i]
      const form = checkbox.form

      // Ajaxify the form
      this.setupFormForXhrSubmit(form)
      this.addAjaxEventListeners(form)

      checkbox.addEventListener('change', (event) => {
        const checkbox = event.target
        const form = checkbox.form

        // Trigger the Rails UJS Ajax submit
        window.Rails.fire(form, 'submit')

        return false
      })

      this.hideElements(form.querySelectorAll('input[type=submit]'))
    }
  }

  addAjaxEventListeners(form) {
    form.addEventListener('ajax:beforeSend', (request, settings) => {
      this.hideElements(form.querySelectorAll('.status .success'))
      this.showElements(form.querySelectorAll('.status .loading'))
    })

    form.addEventListener('ajax:complete', (response, status) => {
      this.hideElements(form.querySelectorAll('.status .loading'))
    })

    form.addEventListener('ajax:error', (response, status, error) => {
      this.addClass(form.querySelectorAll('label.checkbox'), 'error')

      console.error(response)
      console.error(status)
      console.error(error)
    })

    form.addEventListener('ajax:success', (event, data, status, response) => {
      this.showElements(form.querySelectorAll(".status .success"))
    })
  }

  addClass(elements, className) {
    for (var i = elements.length - 1; i >= 0; i--) {
      const element = elements[i]
      element.classList.add('error')
    }
  }

  hide(element) {
    element.style.display = 'none'
  }

  hideElements(elements) {
    for (var i = elements.length - 1; i >= 0; i--) {
      const element = elements[i]
      this.hide(element)
    }
  }

  setupFormForXhrSubmit(form) {
    form.setAttribute('data-remote', 'true')
    this.addAjaxEventListeners(form)
  }

  show(element) {
    element.style.display = ''
  }

  showElements(elements) {
    for (var i = elements.length - 1; i >= 0; i--) {
      const element = elements[i]
      this.show(element)
    }
  }
}
