import { Controller } from "@hotwired/stimulus"

// Notification
//
// Controller for showing and hiding flash messages and notifications
//
// Preferably we'd like to use the following classes from Tailwind UI:
//
// Entering: "transform ease-out duration-300 transition"
//   From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
//   To: "translate-y-0 opacity-100 sm:translate-x-0"
// Leaving: "transition ease-in duration-100"
//   From: "opacity-100"
//   To: "opacity-0"
//
// However, my Javascript-fu is not strong enough to get this to work, so we'll
// keep it simple for now.
export default class extends Controller {
  connect() {
    this.show()
  }

  hide() {
    this.element.classList.replace("opacity-100", "opacity-0")

    // Prepare to be shown
    this.element.classList.replace("duration-100", "duration-300")
    this.element.classList.replace("ease-in", "ease-out")
  }

  show() {
    this.element.classList.add("transform", "ease-out", "duration-300", "transition")
    this.element.classList.replace("opacity-0", "opacity-100")

    // Prepare to be hidden
    this.element.classList.replace("duration-300", "duration-100")
    this.element.classList.replace("ease-out", "ease-in")
  }
}
