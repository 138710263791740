import { Controller } from "@hotwired/stimulus"
import Litepicker from 'litepicker'

// Datepicker
//
// Turns an input field into a more userfriendly date picker widget.
export default class extends Controller {
  static values = {
    format: String
  }

  connect() {
    const picker = new Litepicker({
      element: this.element
    });
  }
}
